/* .footer{
    display: flex;
    height: 110px;
    background-color: darkblue;
    justify-content: center;
    align-items: center;
}

p{
    color: white;
} */

.templatemo-footer{
    height: 100px;
    display: flex;
    bottom: 20px;
    justify-content: center;
}
