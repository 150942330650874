/* Basic Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0 0;
  padding: 10px 20px;
  background: linear-gradient(to right, rgb(255, 111, 0), rgb(0, 89, 255));
  height: 100px; /* Regular height of the navbar */
  transition: height 0.3s ease-in-out; /* Smooth transition when expanding */
  position: relative; /* Ensure relative positioning for stacking context */
}

.navbar .logo {
  height: 60px;
}

/* Navbar Links */
.navbar-links {
  list-style: none;
  display: flex; /* Horizontal by default on large screens */
  gap: 20px;
  margin: 0;
}

.navbar-links li {
  margin: 0;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 10px;
}

.navbar-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  cursor: pointer;
}

/* Hamburger Icon */
.hamburger {
  display: none; /* Hidden by default */
  flex-direction: column;
  gap: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 2; /* Set higher z-index to keep hamburger on top */
  position: absolute;
  left: 20px; /* Place it on the left of the navbar */
}

.hamburger .bar {
  width: 25px;
  height: 4px;
  background-color: white;
  border-radius: 10px;
}

/* Media Query for small screens */
@media screen and (max-width: 900px) {
  /* On small screens, hide navbar links initially */
  .navbar-links {
    display: none; /* Hidden by default */
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: linear-gradient(to right, rgb(255, 111, 0), rgb(0, 89, 255));
    padding: 10px;
    border-radius: 5px;
    z-index: 1; /* Set z-index lower than the hamburger icon */
  }

  /* Display hamburger icon */
  .hamburger {
    display: flex; /* Show hamburger on small screens */
  }

  .hamburger:hover{
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }

  /* Show navbar links when hamburger is clicked */
  .navbar-links.active {
    display: flex;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Adjust navbar height when menu is active */
  .navbar.active {
    height: 300px; /* Expanded navbar height */
  }

  /* Navbar items alignment when dropdown is active */
  .navbar-links li {
    text-align: center;
  }

}


