.loader-container {
    position: relative;
    width: 100%;
    height: 50px;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    background-color: #3498db;
    border-radius: 50%;
    position: absolute;
    left: 0;
    animation: moveDot 2s infinite;
  }
  
  @keyframes moveDot {
    0% {
      left: 0;
    }
    50% {
      left: 50%;
    }
    100% {
      left: 100%;
    }
  }
  